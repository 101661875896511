import {
  Account,
  BetSlip,
  BetSlipItem,
  BetSlipItemType,
  BetSlipSource,
  BetSlipSystem,
  CalculateCashoutResult,
  CashoutRequest,
  CashoutSubmitResult,
  CheckBetBonusRequest,
  CheckBetResult,
  CheckBetSlipOptions,
  CheckBetSlipResult,
  Game,
  Market,
  Odd,
  PrepareBetsRequest,
  PreparedBets,
  SubmitBetSlipResult,
  TestNewBetItemRequest,
  TestNewBetSlipItemResult,
  User,
  ValidTokenBonusRequest,
  ValidTokenBonusResult
} from '@arland-bmnext/api-data'
import useSWR from 'swr'
import { BetSlipContextOdd } from '../context/betslip.context'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus } from './api-client'
import { AxiosClient } from './axiosclient'
import { CheckBetSlipRequest, CheckBetSlipsRequest, PlaceBetSlipRequest, PlaceBetSlipsRequest } from './api-client/bets'
import { TopWinnerDTO } from '@arland-bmnext/webapps-api-data'

export const testNewBetItem = async (request: TestNewBetItemRequest): Promise<TestNewBetSlipItemResult> => {
  const data = await AxiosClient.post<TestNewBetItemRequest, TestNewBetSlipItemResult>(
    '/api/bets/testnewbetitem',
    request
  )
  return data
}

export const placeBet = async (request: PlaceBetSlipRequest): Promise<SubmitBetSlipResult> => {
  const data = await AxiosClient.post<PlaceBetSlipRequest, SubmitBetSlipResult>('/api/bets/placebet', request)
  return data
}

export const placeBets = async (request: PlaceBetSlipsRequest): Promise<SubmitBetSlipResult> => {
  const data = await AxiosClient.post<PlaceBetSlipsRequest, SubmitBetSlipResult>('/api/bets/placebets', request)
  return data
}

export const shareBet = async (request: PrepareBetsRequest): Promise<PreparedBets> => {
  const data = await AxiosClient.post<PrepareBetsRequest, PreparedBets>('/api/bets/sharebets', request)
  return data
}

export const checkBet = async (request: CheckBetSlipRequest): Promise<CheckBetSlipResult> => {
  const data = await AxiosClient.post<CheckBetSlipRequest, CheckBetSlipResult>('/api/bets/checkbet', request)
  return data
}

export const checkBets = async (request: CheckBetSlipsRequest): Promise<CheckBetSlipResult[]> => {
  const data = await AxiosClient.post<CheckBetSlipsRequest, CheckBetSlipResult[]>('/api/bets/checkbets', request)
  return data
}

export const checkBetsWithBonuses = async (
  req: (CheckBetSlipRequest & CheckBetBonusRequest)[]
): Promise<CheckBetResult[]> => {
  const data = await AxiosClient.post<(CheckBetSlipRequest & CheckBetBonusRequest)[], CheckBetResult[]>(
    '/api/bets/checkbets-with-bonuses',
    req
  )
  return data
}

export const getValidTokenBonuses = async (request: ValidTokenBonusRequest[]): Promise<ValidTokenBonusResult[]> => {
  const data = await AxiosClient.post<ValidTokenBonusRequest[], ValidTokenBonusResult[]>(
    '/api/bets/valid-token-bonuses',
    request
  )
  return data
}

export const getBetState = async (submitId: string): Promise<SubmitBetSlipResult> => {
  const data = await AxiosClient.post<string, SubmitBetSlipResult>('/api/bets/bet-state', { submitId })
  return data
}

export const calculateCashout = async (betId: number): Promise<CalculateCashoutResult> => {
  const data = await AxiosClient.post<number, CalculateCashoutResult>('/api/bets/cashout-calculate', { betId })
  return data
}

export const getCashoutState = async (submitId: string): Promise<CashoutSubmitResult> => {
  const data = await AxiosClient.post<string, CashoutSubmitResult>('/api/bets/cashout-state', { submitId })
  return data
}

export const cashoutBet = async (betId: number, request: CashoutRequest): Promise<CashoutSubmitResult> => {
  const data = await AxiosClient.post<any, CashoutSubmitResult>('/api/bets/cashout', { betId, request })
  return data
}

export const buildCheckBetRequest = (
  stake: number,
  selectedOdds: BetSlipContextOdd[],
  user: User,
  account: Account,
  options: CheckBetSlipOptions
): CheckBetSlipRequest => {
  const request: CheckBetSlipRequest = {
    betSlip: buildBetSlip(stake, selectedOdds, user, account),
    options: options
  }
  return request
}

export const buildPlaceBetRequest = (
  stake: number,
  selectedOdds: BetSlipContextOdd[],
  user: User,
  account: Account,
  addToRecent = false
): PlaceBetSlipRequest => {
  const request: PlaceBetSlipRequest = {
    betSlip: buildBetSlip(stake, selectedOdds, user, account),
    addToRecent
  }
  return request
}

const buildBetSlip = (
  stake: number,
  selectedOdds: BetSlipContextOdd[],
  user: User,
  account: Account
): Partial<BetSlip> => {
  const betSlip: Partial<BetSlip> = {
    stake: stake,
    source: 9 as BetSlipSource, // TODO: add enum value whenever available
    userId: user.id,
    branchId: user.branchId,
    accountId: account.id,
    currencyId: account.currencyId,
    items: buildBetSlipItems(selectedOdds) as Partial<BetSlipItem>[] as any,
    systems: [{ combination: selectedOdds.length }] as Partial<BetSlipSystem>[] as any
  }
  return betSlip
}

const buildBetSlipItems = (selectedOdds: BetSlipContextOdd[]): Partial<BetSlipItem>[] => {
  return selectedOdds.map((odd) => {
    const item: Partial<BetSlipItem> = {
      gameId: odd.game.id,
      marketId: odd.market.id,
      oddId: odd.odd.id,
      sportId: odd.game.sportId,
      leagueId: odd.game.leagueId,
      marketTypeId: odd.market.marketTypeId,
      oddValue: odd.odd.value,
      initialOddValue: odd.odd.value,
      type: BetSlipItemType.Regular
    }
    return item
  })
}

export const useBetBuilderFixtureId = (gameId: number): number => {
  const { data, error } = useSWR<number>('/api/bets/bet-builder-fixture?gameId=' + gameId, defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000 // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data
}

export const getBetBuilderItem = async (betslipUid: string): Promise<{ game: Game; market: Market; odd: Odd }> => {
  const data = await AxiosClient.get<{ game: Game; market: Market; odd: Odd }>(
    '/api/bets/bet-builder-item?betSlipUid=' + betslipUid
  )
  return data
}

export const useTopWinners = (startIndex: number, pageSize: number, startDate: string, endDate: string): {
  topWinners: TopWinnerDTO[]
  mutateTopWinners: (data?: TopWinnerDTO[], shouldRevalidate?: boolean) => Promise<TopWinnerDTO[]>
} => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate

  const {
    data,
    error,
    mutate
  } = useSWR<TopWinnerDTO[]>('/api/bets/top-winners' + query, defaultFetcher, { dedupingInterval: 60000 })

  handleHttpErrorStatus(error?.status)

  return {
    topWinners: data,
    mutateTopWinners: mutate
  }
}
