export const gtmPushRegistrationStartedEvent = () => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'RegisterStart',
    })
  }
}

export const gtmPushRegistrationFinishedEvent = (userId: number, promotionCode: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'Register',
      userId,
      promotionCode,
    })
  }
}

export const gtmPushLoginEvent = (userId: number) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'Login',
      userId,
    })
  }
}

export const gtmPushWithdrawalEvent = (
  userId: number,
  transactionId: string,
  amount: number,
  currency: string,
) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'Withdrawal',
      userId,
      transactionId,
      amount,
      currency
    })
  }
}

export const gtmPushDepositEvent = (
  userId: number,
  transactionId: string,
  amount: number,
  currency: string,
  depositCount: number
) => {
  if (typeof window.dataLayer !== 'undefined') {
    if (depositCount === 1) {
      window?.dataLayer?.push({
        event: 'FirstDeposit',
        userId,
        transactionId,
        amount,
        currency,
      })
      return
    }

    window?.dataLayer?.push({
      event: 'Deposit',
      userId,
      transactionId,
      amount,
      currency,
      depositCount,
    })
  }
}

export const gtmPushDepositPreparedEvent = (
  userId: number,
  amount: number,
  currency: string
) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'DepositPrepared',
      userId,
      amount,
      currency,
    })
  }
}

export const gtmPushSportsBetPlacedEvent = (userId: number, betSlipId: number, stake: number, currency: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'SportsBetPlaced',
      userId,
      betSlipId,
      stake,
      currency,
    })
  }
}

export const gtmPushCasinoGameStartedEvent = (userId: number, gameId: number, gameName: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'CasinoGameStarted',
      userId,
      gameId,
      gameName
    })
  }
}

export const gtmPushBannerClickEvent = (userId: number, bannerName: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window?.dataLayer?.push({
      event: 'BannerClick',
      userId,
      bannerName,
    })
  }
}
