import { Currency } from '@arland-bmnext/api-data'

export type CurrencyType = 'shortSign' | 'symbol'
export type CurrencyPosition = 'before' | 'after'

export const addCurrencySymbol = (
  value: number | string,
  currency: Currency,
  type: CurrencyType = 'symbol',
  position: CurrencyPosition = 'before'
) => {
  const c = getCurrencySymbol(currency, type)

  if (position === 'before') return `${c}${value}`
  else return `${value}${c}`
}

export const getCurrencySymbol = (currency: Currency, type: CurrencyType = 'symbol') => {
  return (type === 'symbol' && currency?.symbol != null && currency?.symbol != ''
    ? currency?.symbol
    : currency?.shortSign) ?? ''
}
