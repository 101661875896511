import { Currency } from '@arland-bmnext/api-data'
import { addCurrencySymbol } from './currency'
import {
  getIntlAccountingNumberFormat,
  getIntlDecimalNumberFormat,
  getIntlDecimalOddNumberFormat,
  getIntlPotentialWinningOddNumberFormat,
} from './internationalization'

export const formatOddMoneyOutcome = (num: number, decimalPlaces: number, currency?: Currency) => {
  num = floorNumber(num, 0)

  if (num < 1000) {
    return currency != null ? addCurrencySymbol(num, currency) : num
  }

  let x = ('' + num).length

  const p = Math.pow
  decimalPlaces = p(10, decimalPlaces)
  x -= x % 3

  const value = Math.round((num * decimalPlaces) / p(10, x)) / decimalPlaces + ' KMB'[x / 3]

  return currency != null ? addCurrencySymbol(value, currency) : value
}

export const formatDecimalNumber = (value: number) => {
  return getIntlDecimalNumberFormat(value, 2)
}

export const formatOddValue = (value: number) => {
  return getIntlDecimalOddNumberFormat(value)
}

export const formatPotentialWinningOddValue = (value: number) => {
  return getIntlPotentialWinningOddNumberFormat(value)
}

export const formatMoney = (amount: number, currency: Currency | string, decimalPlaces: number = 2) => {
  const currencyShortSign = typeof currency === 'object' ? currency.shortSign : currency
  return getIntlAccountingNumberFormat(amount, currencyShortSign, decimalPlaces)
}

export const formatPercentage = (n: number) => {
  return formatDecimalNumber(n) + '%'
}

export const formatApayTicketNumber = (n: string) => {
  return n?.replace(/\d{4}(?=.)/g, '$& ')
}

export const padNumber = (num: number, size: number): string => {
  let numString = num.toString()
  while (numString.length < size) numString = '0' + num
  return numString
}

export const floorNumber = (num: number, decimalPlaces: number = 2): number => {
  const indexDecimalSeperator = num?.toString().indexOf('.')
  if (indexDecimalSeperator > 0) {
    const end = indexDecimalSeperator + decimalPlaces + 1
    num = Number(num.toString().substring(0, end))
  }
  return num
}

export const countDecimals = (value: string | number) => {
  const v = parseFloat(value.toString().replace(',', '.'))
  if (Math.floor(v) !== v) return v?.toString()?.split('.')[1]?.length || 0
  return 0
}

export const greatestCommonDivider = (a: number, b: number) => {
  if (b < 0.0000001) return a
  return greatestCommonDivider(b, Math.floor(a % b))
}
