import { Odd } from "@arland-bmnext/api-data";
import { filter, Observable, Subject } from "rxjs";

const oddChangeSubject = new Subject<Odd>()

const oddsChange = (odd: Odd): void => {
  oddChangeSubject.next(odd)
}

const onOddsChange = (oddId = null): Observable<Odd> => {
  return oddChangeSubject.asObservable().pipe(filter((odd: Odd) => odd && (oddId != null ? odd.id === oddId : true)))
}

export const oddsChangeService =  {
  oddsChange,
  onOddsChange
}