import {
  Account,
  BetSlip,
  BetSlipItem,
  BetSlipItemType,
  BetSlipSource,
  CheckBetSlipResult,
  Game,
  Market,
  Odd,
  PlaceBetSlipResult,
  TestNewBetSlipItemResult,
  UserType,
  ValidTokenBonus,
} from '@arland-bmnext/api-data'

export class BetSlipServiceResult {
  public items: BetSlipServiceBetSlipItem[] = []
  public bets: BetSlipServiceStateBet[] = []
  public modifyBetSlipResult: ModifyBetSlipResult
  public now: Date
  public submitted?: Date
  public waitSeconds?: number
  public possibleSystemCombinations: number[]
  public canBank: boolean
  public minimumCombination: number
  public betMode: BetMode
}

export class BetSlipInfo {
  public items: BetSlipServiceBetSlipItem[] = []
  public bets: BetSlipServiceStateBet[] = []
  public modifyBetSlipResult: ModifyBetSlipResult
  public now: Date
  public submitted?: Date
  public waitSeconds?: number
  public possibleSystemCombinations: number[]
  public canBank: boolean
  public minimumCombination: number
  public betMode: BetMode
  public itemMap: BetSlipServiceBetSlipItem[] = []
  public submittedGuid: string
  public submittedItems: BetValue[] = []
  public userId: number
  public userType: UserType
  public accountId: number
  public accounts: Account[] = []
  public mainAccountId: number
  public branchId: number
  public currencyId: number
  public languageId: number
  public source: BetSlipSource
  public createdBets: BetSlipServiceBet[] = []
  public minimumCombinationMet: boolean
  public remaining: number
  public bankAllowed: boolean
  public defaultStake: number

  public isSubmitted(): boolean {
    return this.submittedGuid != null && this.submittedGuid != ''
  }
}

export class BetSlipServiceBetSlip extends BetSlip {
  public betItems: BetSlipServiceBetSlipItem[]
}

export class BetSlipServiceBetSlipItem extends BetSlipItem {
  game: Game
  market: Market
  odd: Odd
  enabled: boolean
  automaticDisabled: boolean

  constructor(
    game: Game,
    odd: Odd,
    market: Market,
    type: BetSlipItemType = BetSlipItemType.Regular,
    enabled: boolean = true,
  ) {
    super()
    this.game = game
    this.gameId = game.id
    this.odd = odd
    this.oddId = odd.id
    this.oddValue = odd.value
    this.market = market
    this.marketId = market.id
    this.marketTypeId = market.marketTypeId
    this.initialOddValue = this.oddValue
    this.type = type
    this.enabled = enabled
    this.sportId = game.sportId
    this.leagueId = game.leagueId
    this.producer = game.mode // idk???
  }
}

export class BetSlipServiceStateBet {
  public identifier: string
  public state: BetSlipServiceStateBetState
  public checkBetResult: CheckBetSlipResult & { validTokenBonuses?: ValidTokenBonus[] }
  public placeBetResult: PlaceBetSlipResult
  public betSlip: BetSlip
}

export enum BetSlipServiceStateBetState {
  Open = 0,
  Booked = 1,
  Error = 2,
}

export class ModifyBetSlipResult {
  public testNewItemResult: TestNewBetSlipItemResult
  public status: ModifyBetSlipResultStatus
  public gameId: number
  public oddId: number
  public combination: number
  public remaining: number
}

export enum ModifyBetSlipResultStatus {
  Ok = 0,
  TestNewBetItemError = 1,
  GameNotOpen = 2,
  OddNotOpen = 3,
  BankerAndMultiWayNotPossible = 4,
  MinimumCombiNotMet = 5,
}

export enum BetTypes {
  None = 0,
  Single = 1,
  Combi = 2,
  System = 4,
  Extended = 8,
}

export class BetKey {
  public type: BetKeyType
  public value: number

  public static toPrefix(t: BetKeyType): string {
    switch (t) {
      case BetKeyType.Single:
        return 's'
      case BetKeyType.Extended:
        return 'x'
      case BetKeyType.Combi:
        return 'c'
      case BetKeyType.System:
        return 'y'
    }
  }

  public static parse(s: string): BetKey {
    let key = new BetKey()
    let t: BetKeyType
    switch (s[0]) {
      case 's':
        t = BetKeyType.Single
        break
      case 'y':
        t = BetKeyType.System
        break
      case 'c':
        t = BetKeyType.Combi
        break
      case 'x':
        t = BetKeyType.Extended
        break
    }
    key.type = t
    key.value = parseInt(s.substring(1))
    return key
  }

  public equals(key: BetKey): boolean {
    return this.type == key.type && this.value == key.value
  }

  public toString(): string {
    return BetKey.toPrefix(this.type) + this.value
  }
}

export enum BetKeyType {
  Unknown,
  Single,
  System,
  Combi,
  Extended,
}

export class BetValue {
  public betSlip: BetSlip
  public checkBetResult: CheckBetSlipResult & { validTokenBonuses?: ValidTokenBonus[] }
  public placeBetResult: PlaceBetSlipResult
  public state: BetSlipServiceStateBetState
}

export class BetSlipServiceBet {
  public key: BetKey
  public value: BetValue
}

export class BetServiceChangedOdd {
  public newValue: number
  public oddId: number
  public oldValue: number

  public constructor(oddId: number, oldValue: number, newValue: number) {
    this.oddId = oddId
    this.oldValue = oldValue
    this.newValue = newValue
  }
}

export class BetSlipCalculateRequest {
  public refreshOddValues: boolean
  public acceptedChangedOdds?: BetServiceChangedOdd[] = []
  public stake: number
  public stakeMode: StakeMode
}

export class BetSlipSubmitRequest {
  public betsToSubmit: string[]
  public maxGainConfirmed: boolean
  public refreshOddValues: boolean
  public acceptedChangedOdds?: BetServiceChangedOdd[] = []
}

export class BetSlipShareRequest {
  public betsToShare: string[]
}

export enum BetMode {
  Stake = 0,
  FreeBet = 1,
}

export class BetSystemInfo {
  public identifier: string
  public combi: number
  public betAmount: number

  public constructor(identifier: string = '', combi: number = 0, betAmount: number = 0) {
    this.identifier = identifier
    this.combi = combi
    this.betAmount = betAmount
  }
}

export type StakeMode = 'totalStake' | 'stakePerBet'
