import {
  Account,
  AccountType,
  BetSlip,
  BetSlipItemType,
  BetSlipSource,
  BonusType,
  CheckBetSlipError,
  CheckBetSlipErrorType,
  CheckBetSlipItemError,
  CheckBetSlipItemStatus,
  Currency,
  Game,
  Market,
  Odd,
  PlaceBetSlipResultType,
  UserChangeHandling,
  ValidTokenBonus,
} from '@arland-bmnext/api-data'
import { CardEventModel } from '@arland-bmnext/api-data/odds/cardEventModel'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { Subject } from 'rxjs'
import { mapOddsDisplayFormatToOddValueFormat, OddValueFormat } from '../hooks/useOddsFormat'
import { useNodeBetSettings } from '../lib/account'
import { useBetSharingTextsContent, useFrontendSettings, useSportsBettingSettings } from '../lib/content'
import {
  isLoggedInCustomer,
  mutateOpenUserBets,
  useUser,
  useUserAccounts,
  useUserBetSettings,
  useUserDisplayFormats,
} from '../lib/user'
import {
  BetServiceChangedOdd,
  BetSlipServiceBetSlipItem,
  BetSlipServiceResult,
  BetSlipServiceStateBet,
  BetSlipShareRequest,
  BetSlipSubmitRequest,
  BetSystemInfo,
  BetTypes,
  StakeMode,
} from '../services/bet/bet.models'
import { BetSlipService } from '../services/bet/bet.service'
import { oddsChangeService } from '../services/oddschange.service'
import { getFirstOrDefault, sortArray } from '../util/array'
import { gtmPushSportsBetPlacedEvent } from '../util/google-tag-manager'
import { floorNumber, formatOddMoneyOutcome } from '../util/number'
import {
  getAmericanOddsFormatValue,
  getDecimalOddsFormatValue,
  getFractionalOddsFormatValue,
  getHongKongOddsFormatValue,
} from '../util/odds'
import { useCommonContext } from './common.context'
import { getCmsContentDataFieldValue } from '../util/content'
import useCurrentLanguage from '../hooks/useCurrentLanguage'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'

export type BetSlipContextOdd = {
  odd: Odd
  market: Market
  game: Game
  sportName: string
  sportShortSign: string
  leagueName: string
}

export type BetSlipContextItemErrorType = 'warning' | 'error'

export type BetSlipContextItemError = {
  type: BetSlipContextItemErrorType
  status: CheckBetSlipItemStatus
  oddId: number
  gameId: number
  gameStartDate: Date
  oddValue: number
  leagueId: number
}

export type BetSlipContextOddState = 'selected' | 'other_game_odd_selected' | 'checking' | 'unselected'

export enum BetslipState {
  Initial,
  PlacingBet,
  PlaceBetSuccessful,
  PlaceBetFailed,
}

export type ShareBetSlipResult = {
  title: string
  text?: string
  url?: string
}

type BetValues = {
  betAmount: number
  totalStake: number
  stakePerBet: number
  stakeFee: number
  stakeTax: number
  gainTax: number
  chargedAmount: number
  stakeBonus: number
  gainBonus: number
  maxOdd: number
  initialMaxOdd: number
  maxReturn: number
  maxGain: number
}
const initialBetValues: BetValues = {
  totalStake: 0,
  stakePerBet: 0,
  stakeFee: 0,
  stakeTax: 0,
  gainTax: 0,
  maxOdd: 0,
  initialMaxOdd: 0,
  maxReturn: 0,
  maxGain: 0,
  stakeBonus: 0,
  gainBonus: 0,
  betAmount: 0,
  chargedAmount: 0,
}

type BetslipContextProps = {
  initialized: boolean
  betslipState: BetslipState
  betSlipServiceResult: BetSlipServiceResult
  account: Account
  stake: number
  totalStake: number
  stakePerBet: number
  betAmount: number
  maxOdd: number
  initialMaxOdd: number
  stakeBonus: number
  stakeFee: number
  stakeTax: number
  gainTax: number
  chargedAmount: number
  maxReturn: number
  gainBonus: number
  betslips: BetSlip[]
  betSystems: BetSystemInfo[]
  selectedBetSystem: string
  toggleBetSystem: (identifier: string) => void
  selectedOdds: BetSlipServiceBetSlipItem[]
  betSlipItemErrors: BetSlipContextItemError[]
  checkBetSlipErrors: CheckBetSlipError[]
  placeBetSlipErrors: PlaceBetSlipResultType[]
  placeBetLiveDelay?: number
  setStake?: (stake: number, stakeMode: StakeMode) => Promise<boolean>
  toggleAccount?: () => Promise<void>
  toggleOdd?: (odd: Odd, market: Market, game: CardEventModel) => Promise<void>
  toggleOddBanked?: (item: BetSlipServiceBetSlipItem) => Promise<void>
  getOddState?: (odd: Odd, gameId: number) => BetSlipContextOddState
  placeBetSlip?: () => Promise<void>
  shareBetSlip?: () => Promise<void>
  restoreBetSlip?: (sharedBetSlip: { referenceId: string; data: string }) => Promise<boolean>
  clearBetSlip?: () => void
  changedLiveOdds?: BetServiceChangedOdd[]
  mustAcceptChanges?: () => { mustAcceptOddsChanges: boolean; mustAcceptAvailabilityChanges: boolean }
  acceptChanges?: () => Promise<void>
  getOddValue?: (odd: Odd, game: CardEventModel, currency?: Currency) => string | number
  getGames?: () => CardEventModel[]
  removeOdd?: (oddId: number) => Promise<void>
  checkForItemErrors?: (oddId: number) => BetSlipContextItemError[]
  placeBetPossible?: (ignoreBetCalculation?: boolean) => boolean
  maxBetSlipItemsReachedEvent?: Subject<number>
  maxReturnReachedEvent?: Subject<number>
  nonCombinableGameAddedEvent?: Subject<string>
  checkBetSlipErrorEvent?: Subject<CheckBetSlipErrorType>
  maxStakeExceededEvent?: Subject<number>
  minStakeNotReachedEvent?: Subject<number>
  stakeExceededFreebetAccountBalanceEvent?: Subject<number>
  stakeExceededMainAccountBalanceEvent?: Subject<number>
  systemBetsNoPotentialWinningModeEvent?: Subject<void>
  shareBetWarning?: () => void
  retrieveSharedBetErrorEvent?: Subject<void>
  betType?: BetTypes
  setBetType?: (type: BetTypes) => Promise<void>
  isBetTypeAcitvated?: (type: BetTypes) => boolean
  isBlockingItemError?: (error: CheckBetSlipItemError) => boolean
  validTokenBonuses?: () => ValidTokenBonus[]
  selectedTokenBonus?: ValidTokenBonus
  toggleTokenBonus?: (bonusId: number) => void
  addBetBuilderOdd?: (odd: Odd, market: Market, game: Game) => Promise<void>
  shareData?: ShareData
  clearShareData?: () => void
  isShared?: boolean
  stakeSelectorActive?: boolean
  setStakeSelectorActive?: (val: boolean) => void
}

export const BetslipContext = createContext<BetslipContextProps | undefined>(undefined)

const maxBetSlipItemsReachedEvent = new Subject<number>()
const maxReturnReachedEvent = new Subject<number>()
const maxStakeExceededEvent = new Subject<number>()
const minStakeNotReachedEvent = new Subject<number>()
const stakeExceededFreebetAccountBalanceEvent = new Subject<number>()
const stakeExceededMainAccountBalanceEvent = new Subject<number>()
const nonCombinableGameAddedEvent = new Subject<string>()
const checkBetSlipErrorEvent = new Subject<CheckBetSlipErrorType>()
const systemBetsNoPotentialWinningModeEvent = new Subject<void>()
const retrieveSharedBetErrorEvent = new Subject<void>()

export const useBetslipContext = (suppressScopeCheck: boolean = false): BetslipContextProps => {
  const value = useContext(BetslipContext)
  if (process.env.NODE_ENV !== 'production' && !suppressScopeCheck) {
    if (value === undefined) {
      throw new Error('useBetslipContext must be wrapped in a <BetslipProvider />')
    }
  }
  return value
}

const BetslipProvider = ({ children }) => {
  const router = useRouter()
  const commonContext = useCommonContext()
  const { nodeBetSettings } = useNodeBetSettings()
  const sportsBettingSettings = useSportsBettingSettings()
  const language = useCurrentLanguage()
  const { betSharingTextsContent } = useBetSharingTextsContent(language?.id)
  const { mainAccount, freebetAccount, freebetTokenAccount, mutateUserAccounts } = useUserAccounts()
  const { betSettings } = useUserBetSettings()
  const frontEndSettings = useFrontendSettings()

  const [betSlipService, setBetSlipService] = useState<BetSlipService>(new BetSlipService())

  const [_initialized, _setInitialized] = useState<boolean>(false)
  const [_betslipState, _setBetslipState] = useState<BetslipState>(BetslipState.Initial)
  const _betslipStateRef = useRef(_betslipState)
  const { user } = useUser()
  const _userRef = useRef(user)
  const { displayFormats, mutateUserDisplayFormats } = useUserDisplayFormats(user?.id)
  const [_defaultStake, _setDefaultStake] = useState<number>(null)
  const _defaultStakeRef = useRef(_defaultStake)
  const [_stake, _setStake] = useState<number>(null)
  const _stakeRef = useRef(_stake)
  const [_account, _setAccount] = useState<Account>(null)
  const _accountRef = useRef(_account)
  const _mainAccountRef = useRef(mainAccount)

  const [_placeBetLiveDelay, _setPlaceBetLiveDelay] = useState<number>(null)

  const [_betType, _setBetType] = useState<BetTypes>(BetTypes.Combi)
  const _betTypeRef = useRef(_betType)
  const [_betSlipServiceResult, _setBetSlipServiceResult] = useState<BetSlipServiceResult>(null)
  const _betSlipServiceResultRef = useRef(_betSlipServiceResult)
  const [_isBetSlipCalculationInProgress, _setIsBetSlipCalculationInProgress] = useState<boolean>(false)

  const [_betsForBetType, _setBetsForBetType] = useState<BetSlipServiceStateBet[]>([])
  const _betsForBetTypeRef = useRef(_betsForBetType)

  const [_betsToSubmit, _setBetsToSubmit] = useState<BetSlipServiceStateBet[]>([])
  const _betsToSubmitRef = useRef(_betsToSubmit)

  const [_betValues, _setBetValues] = useState<BetValues>(initialBetValues)

  const [_betSystems, _setBetSystems] = useState<BetSystemInfo[]>([])
  const [_selectedBetSystem, _setSelectedBetSystem] = useState<string>(null)
  const _selectedBetSystemRef = useRef(_selectedBetSystem)

  const [_availableTokenBonuses, _setAvailableTokenBonuses] = useState<ValidTokenBonus[]>([])
  const [_selectedTokenBonus, _setSelectedTokenBonus] = useState<number>(null)

  const [_changedLiveOdds, _setChangedLiveOdds] = useState<BetServiceChangedOdd[]>([])
  const _changedLiveOddsRef = useRef(_changedLiveOdds)

  const [_shareData, _setShareData] = useState<ShareBetSlipResult>(null)
  const [_getSharedBetWarning, _setGetSharedBetWarning] = useState<boolean>(false)
  const [_isShared, _setIsShared] = useState<boolean>(false)

  const [_stakeSelectorActive, _setStakeSelectorActive] = useState<boolean>(false)

  const isLoggedIn = isLoggedInCustomer(user)

  useEffect(() => {
    if (nodeBetSettings != null && user != null && (isLoggedIn ? mainAccount != null && betSettings != null : true)) {
      const defaultStake = betSettings?.defaultStake ?? nodeBetSettings?.defaultStake ?? 10

      betSlipService.setUserData(
        nodeBetSettings,
        9 as BetSlipSource,
        user,
        [mainAccount, freebetAccount, freebetTokenAccount],
        defaultStake,
      )
      _setAccount(mainAccount)
      _setDefaultStake(defaultStake)
      _setSelectedTokenBonus(null)
      _setAvailableTokenBonuses([])
      _setInitialized(true)

      // do not restore from local storage if referenceId is provided
      if (router.query.referenceId != null) return

      restoreBetSlip().then((restored) => {
        if (!restored) {
          _setBetType(getInitialBetType())
          _setStake(defaultStake)
        }
      })
    }
  }, [nodeBetSettings, user?.id, mainAccount?.id, freebetAccount?.id, betSettings?.defaultStake])

  useEffect(() => {
    _setBetslipState(BetslipState.Initial)

    betSlipService.init()
    const betSlipServiceSub = betSlipService.betslip.subscribe(onBetSlipChange)
    const betSlipServiceBetCalculationSub = betSlipService.betCalculationInProgress.subscribe(
      onIsBetSlipCalculationInProgressChange,
    )
    const oddsChangeSub = oddsChangeService.onOddsChange().subscribe(onLiveOddsChange)

    return () => {
      betSlipService.cleanup()
      betSlipServiceSub.unsubscribe()
      betSlipServiceBetCalculationSub.unsubscribe()
      oddsChangeSub.unsubscribe()
    }
  }, [])

  useEffect(() => {
    _betSlipServiceResultRef.current = _betSlipServiceResult
  }, [_betSlipServiceResult])

  useEffect(() => {
    _betsForBetTypeRef.current = _betsForBetType
  }, [_betsForBetType])

  useEffect(() => {
    _betsToSubmitRef.current = _betsToSubmit
  }, [_betsToSubmit])

  useEffect(() => {
    _selectedBetSystemRef.current = _selectedBetSystem
  }, [_selectedBetSystem])

  useEffect(() => {
    updateAccount()
  }, [mainAccount, freebetAccount, freebetTokenAccount])

  useEffect(() => {
    _stakeRef.current = _stake
  }, [_stake])

  useEffect(() => {
    _defaultStakeRef.current = _defaultStake
  }, [_defaultStake])

  useEffect(() => {
    _userRef.current = user
  }, [user])

  useEffect(() => {
    _accountRef.current = _account
  }, [_account])

  useEffect(() => {
    _mainAccountRef.current = mainAccount
  }, [mainAccount])

  useEffect(() => {
    _betslipStateRef.current = _betslipState
  }, [_betslipState])

  useEffect(() => {
    _betTypeRef.current = _betType
  }, [_betType])

  useEffect(() => {
    _changedLiveOddsRef.current = _changedLiveOdds
  }, [_changedLiveOdds])

  useEffect(() => {
    if (_stake != null && _betSlipServiceResult?.items != null && _betType != null) storeBetSlip()
  }, [_stake, _betSlipServiceResult, _betType, _selectedBetSystem])

  useEffect(() => {
    if ((displayFormats === undefined && isLoggedIn) || sportsBettingSettings == null) return

    const possibleFormats = ((sportsBettingSettings.oddsDisplay?.oddsFormat as any)
      ?.enabledModes as OddValueFormat[]) ?? [
      OddValueFormat.PotentialWinnings,
      OddValueFormat.DecimalOdds,
      OddValueFormat.AmericanOdds,
      OddValueFormat.HongKongOdds,
    ]

    const oddsFormatSettings = sportsBettingSettings.oddsDisplay?.oddsFormat

    if (oddsFormatSettings) {
      const userDefinedOddsFormat = mapOddsDisplayFormatToOddValueFormat(displayFormats?.oddsFormat)
      if (userDefinedOddsFormat != null && possibleFormats.includes(userDefinedOddsFormat)) {
        commonContext.setOddsFormat(userDefinedOddsFormat)
        return
      }

      if (
        !oddsFormatSettings.customerCanChoose ||
        !possibleFormats.includes(commonContext.oddsFormat) ||
        commonContext.oddsFormat == null
      ) {
        commonContext.setOddsFormat(oddsFormatSettings.defaultMode as any)
      }
    }

    if (sportsBettingSettings != null && oddsFormatSettings == null) {
      commonContext.setOddsFormat(OddValueFormat.PotentialWinnings)
    }
  }, [sportsBettingSettings, commonContext.oddsFormat, displayFormats])

  useEffect(() => {
    if (_betType === BetTypes.System && commonContext.oddsFormat === OddValueFormat.PotentialWinnings) {
      systemBetsNoPotentialWinningModeEvent.next()
    }
  }, [commonContext.oddsFormat])

  useEffect(() => {
    if (_getSharedBetWarning == true) {
      retrieveSharedBetErrorEvent.next()
    }
  }, [_getSharedBetWarning])

  useEffect(() => {
    if (_betslipState === BetslipState.PlaceBetSuccessful) {
      clearBetSlip()
    }
  }, [router.pathname])

  const storeBetSlip = () => {
    const betslipData = JSON.stringify({
      stake: _stake,
      betSlipItems: _betSlipServiceResult.items,
      betType: _betType,
      selectedBetSystem: _selectedBetSystem,
      createdAt: Date.now(),
    })
    try {
      localStorage.setItem('betslip', betslipData)
    } catch (error) {
      console.error('Could not store betslip!')
    }
  }

  const restoreBetSlip = async (sharedBetSlip: { referenceId: string; data: string } = null): Promise<boolean> => {
    let betslipData: any = sharedBetSlip ? sharedBetSlip.data : localStorage.getItem('betslip')

    if (betslipData) {
      betslipData = JSON.parse(betslipData)

      if (betslipData.createdAt) {
        const diffHours = dayjs().diff(dayjs(betslipData.createdAt), 'hours')
        if (diffHours > 2) {
          localStorage.removeItem('betslip')
          return false
        }
      }

      if (betslipData.stake != null && betslipData.betSlipItems?.length > 0) {
        _setStake(betslipData.stake)
        _setBetType(isBetTypeAcitvated(betslipData.betType) ? betslipData.betType : getInitialBetType())
        _setSelectedBetSystem(betslipData.selectedBetSystem)

        betSlipService.changeStake(
          betslipData.stake,
          betslipData.betType === BetTypes.Single ? 'stakePerBet' : 'totalStake',
        )

        await betSlipService.reuseBetslipItems(
          betslipData.betSlipItems,
          betslipData.betType,
          sharedBetSlip?.referenceId,
        )

        sharedBetSlip != null ? _setIsShared(true) : _setIsShared(false)

        return true
      }
    }

    return false
  }

  const isBetTypeAcitvated = (betType: BetTypes): boolean => {
    switch (betType) {
      case BetTypes.Single:
        return sportsBettingSettings?.betSlipOptions?.activateSingle
      case BetTypes.Combi:
        return sportsBettingSettings?.betSlipOptions?.activateCombi
      case BetTypes.System:
        return sportsBettingSettings?.betSlipOptions?.activateSystem
      default:
        return false
    }
  }

  const getInitialBetType = (): BetTypes => {
    if (sportsBettingSettings?.betSlipOptions?.activateCombi) {
      return BetTypes.Combi
    } else if (sportsBettingSettings?.betSlipOptions?.activateSingle) {
      return BetTypes.Single
    } else if (sportsBettingSettings?.betSlipOptions?.activateSystem) {
      return BetTypes.System
    } else {
      // What to do?
      return BetTypes.Combi
    }
  }

  const updateAccount = () => {
    const curAccount = _accountRef.current
    if (mainAccount != null && curAccount?.id === mainAccount?.id) {
      _setAccount(mainAccount)
    } else if (freebetAccount != null && curAccount?.id === freebetAccount?.id) {
      if (freebetAccount.balance <= 0.01) {
        return _setAccount(mainAccount)
      }
      _setAccount(freebetAccount)
    } else if (freebetTokenAccount != null && curAccount?.id === freebetTokenAccount?.id) {
      if (freebetTokenAccount.balance <= 0.01) {
        return _setAccount(mainAccount)
      }
      _setAccount(freebetTokenAccount)
    }
  }

  const onBetSlipChange = async (betSlipServiceResult: BetSlipServiceResult) => {
    console.log('[DEBUG] onBetSlipChange', JSON.parse(JSON.stringify(betSlipServiceResult)))

    if (betSlipServiceResult?.items?.length > 0 && betSlipServiceResult?.bets?.length === 0) return

    const bets = getBetsForBetType(betSlipServiceResult)
    const betValues: BetValues = calcBetValues(bets?.betsToSubmit)
    checkForBetSlipWarnings(bets?.betsToSubmit)

    _setBetsForBetType(bets?.betsForBetType ?? [])
    _setBetsToSubmit(bets?.betsToSubmit ?? [])
    _setBetSystems(bets?.betSystems)
    _setSelectedBetSystem(bets?.selectedBetSystem)
    _setBetValues(betValues)
    _setBetSlipServiceResult(betSlipServiceResult)

    const availableTokenBonuses =
      bets?.betsToSubmit?.length === 1 ? bets.betsToSubmit[0].checkBetResult?.validTokenBonuses : []

    if (bets?.betsToSubmit?.length === 1 && bets.betsToSubmit[0].betSlip.givenTokenBonusId == null) {
      _setAvailableTokenBonuses(sortArray(availableTokenBonuses, 'givenBonusId', 'desc'))
    }

    if (bets?.betsToSubmit?.length > 1) {
      _setAvailableTokenBonuses([])
      _setSelectedTokenBonus(null)
      betSlipService.setGivenTokenBonusId(null)
    }

    await checkForPlacedBets(betSlipServiceResult)
  }

  const onIsBetSlipCalculationInProgressChange = (val: boolean) => {
    _setIsBetSlipCalculationInProgress(val)
  }

  const checkForBetSlipWarnings = (bets: BetSlipServiceStateBet[]) => {
    for (let bet of bets ?? []) {
      if (bet.betSlip?.initialMaximumGain > bet.betSlip?.initialMaximumReturnedAmount) {
        maxReturnReachedEvent.next(bet.betSlip.initialMaximumReturnedAmount)
        return
      }

      const checkError = getFirstOrDefault(bet.checkBetResult?.errors)
      if (checkError && checkError.type != CheckBetSlipErrorType.None) {
        checkBetSlipErrorEvent.next(checkError.type)
        return
      }
    }
  }

  const checkForPlacedBets = async (betSlipServiceResult: BetSlipServiceResult) => {
    const betslipState = _betslipStateRef.current
    const account = _accountRef.current

    if (betslipState != BetslipState.PlacingBet) return

    const placeBetResults = betSlipServiceResult?.bets?.filter((bet) => bet.placeBetResult != null)
    const withPlaceBetOk = placeBetResults?.filter(
      (bet) => bet.placeBetResult.placeResultType === PlaceBetSlipResultType.Ok,
    )

    if (betSlipServiceResult.submitted == null && withPlaceBetOk?.length > 0) {
      _setBetslipState(BetslipState.PlaceBetSuccessful)

      withPlaceBetOk.forEach((bet) =>
        gtmPushSportsBetPlacedEvent(
          user?.id,
          bet?.betSlip?.id,
          bet?.betSlip?.stake,
          commonContext.getCurrencyById(bet?.betSlip?.currencyId)?.shortSign,
        ),
      )

      if (account?.id !== _mainAccountRef?.current?.id) {
        _setAccount(_mainAccountRef?.current)
        betSlipService.changeAccount(_mainAccountRef?.current?.id)
      }

      _setPlaceBetLiveDelay(null)
      _setSelectedTokenBonus(null)
      _setAvailableTokenBonuses([])
      betSlipService.setGivenTokenBonusId(null)

      mutateUserAccounts()
      mutateOpenUserBets(sportsBettingSettings.cashoutEnabled)
    } else if (betSlipServiceResult.submitted != null) {
      _setPlaceBetLiveDelay(betSlipServiceResult.waitSeconds)
      setTimeout(() => betSlipService.getCurrentState(), (betSlipServiceResult.waitSeconds + 1) * 1000)
    } else {
      _setBetslipState(BetslipState.Initial)
    }
  }

  const getBetsForBetType = (
    betSlipServiceResult: BetSlipServiceResult,
  ): {
    betsForBetType: BetSlipServiceStateBet[]
    betsToSubmit: BetSlipServiceStateBet[]
    betSystems: BetSystemInfo[]
    selectedBetSystem: string
  } => {
    const betType = _betTypeRef.current

    let betsForBetType = []
    let betsToSubmit = []
    let betSystems = []
    let selectedBetSystem = null

    if (betSlipServiceResult == null || betSlipServiceResult.bets?.length === 0) {
      _setBetsForBetType(betsForBetType)
      _setBetsToSubmit(betsToSubmit)
      return
    }

    switch (betType) {
      case BetTypes.Single:
        betsForBetType = betSlipServiceResult.bets?.filter((bet) => bet.identifier[0] === 's')
        betsToSubmit = betsForBetType
        break
      case BetTypes.Combi:
        const combiBet = betSlipServiceResult.bets?.find((bet) => bet.identifier[0] === 'c')
        if (combiBet) {
          betsForBetType.push(combiBet)
          betsToSubmit = betsForBetType
        }
        break
      case BetTypes.System:
        betsForBetType = betSlipServiceResult.bets?.filter((bet) => bet.identifier[0] === 'y')
        betSystems = buildBetSystems(betsForBetType)
        const res = buildSelectedBetSystem(betsForBetType)
        betsToSubmit = res?.betsToSubmit
        selectedBetSystem = res?.selectedBetSystem
        break
    }

    return { betsForBetType, betsToSubmit, betSystems, selectedBetSystem }
  }

  const calcBetValues = (betsToSubmit: BetSlipServiceStateBet[]): BetValues => {
    let betAmount = 0
    let maxOdd = 0
    let initialMaxOdd = 0
    let stakeBonus = 0
    let stakeFee = 0
    let stakeTax = 0
    let gainTax = 0
    let chargedAmount = 0
    let maxGain = 0
    let maxReturn = 0
    let gainBonus = 0
    let totalStake = 0
    let stakePerBet = 0

    betsToSubmit?.forEach((bet) => {
      betAmount += bet.betSlip?.betCount ?? 0
      maxOdd += bet.betSlip?.currentMaximumOdd ?? 0
      initialMaxOdd += bet.betSlip?.initialMaximumOdd ?? 0
      totalStake += bet.betSlip?.stake ?? 0
      stakeBonus += bet.betSlip?.stakeBonus ?? 0
      stakeFee += bet.betSlip?.stakeFee ?? 0
      stakeTax += bet.betSlip?.stakeTax ?? 0
      gainTax += (bet.betSlip as any)?.currentGainTax ?? 0
      chargedAmount += bet.betSlip?.chargedAmount ?? 0
      maxReturn += bet.betSlip?.currentMaximumReturnedAmount ?? 0
      maxGain += bet.betSlip?.currentMaximumGain ?? 0
      gainBonus += bet.betSlip?.gainBonus ?? 0
    })

    if (betAmount > 0) stakePerBet = totalStake / betAmount

    console.log(
      `[DEBUG] updateBetValues
        Total Stake: ${totalStake}
        Stake per bet: ${stakePerBet}
        Stake Tax: ${stakeTax}
        Charged amount: ${chargedAmount}
        Max. Odd: ${maxOdd}
        Max. Return: ${maxReturn}
        `,
      betsToSubmit ? JSON.parse(JSON.stringify(betsToSubmit)) : null,
    )

    return {
      betAmount,
      maxOdd,
      initialMaxOdd,
      totalStake,
      stakePerBet,
      stakeBonus,
      stakeFee,
      stakeTax,
      gainTax,
      chargedAmount,
      maxGain,
      maxReturn,
      gainBonus,
    }
  }

  const buildBetSystems = (betsForBetType: BetSlipServiceStateBet[]) => {
    const betSystems: BetSystemInfo[] = []
    betsForBetType?.forEach((bet) => {
      const betSystem = new BetSystemInfo(bet.identifier, bet.betSlip?.systems[0]?.combination, bet.betSlip?.betCount)
      betSystems.push(betSystem)
    })
    return betSystems
  }

  const buildSelectedBetSystem = (betsForBetType: BetSlipServiceStateBet[]) => {
    const betsToSubmit = []
    let selectedBetSystem = _selectedBetSystemRef.current

    if (
      (selectedBetSystem == null && betsForBetType.length > 0) ||
      betsForBetType.find((bet) => bet.identifier === selectedBetSystem) == null
    ) {
      const defaultSystem = betsForBetType[betsForBetType.length - 1]
      selectedBetSystem = defaultSystem?.identifier
    }

    betsForBetType.forEach((bet) => {
      if (selectedBetSystem === bet.identifier) {
        betsToSubmit.push(bet)
      }
    })

    return { betsToSubmit, selectedBetSystem }
  }

  const toggleBetSystem = async (identifier: string) => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    const betToSubmit = _betsForBetType.find((bet) => bet.identifier === identifier)
    const betValues: BetValues = calcBetValues([betToSubmit])
    _setSelectedBetSystem(identifier)
    _setBetsToSubmit([betToSubmit])
    _setBetValues(betValues)
  }

  const onLiveOddsChange = async (changedOdd: Odd) => {
    const changedLiveOdds = [..._changedLiveOddsRef.current]
    const selectedOdds = [...(_betSlipServiceResultRef.current?.items ?? [])]

    let modifiedOddsCount = 0

    if (selectedOdds) {
      const selectedOdd = selectedOdds?.find((odd) => odd.odd.id === changedOdd.id)
      if (selectedOdd) {
        const existingChangedOdd = changedLiveOdds.find((odd) => odd.oddId === changedOdd.id)
        if (existingChangedOdd) {
          existingChangedOdd.newValue = changedOdd.value
        } else {
          changedLiveOdds.push(new BetServiceChangedOdd(changedOdd.id, selectedOdd.oddValue, changedOdd.value))
        }
        modifiedOddsCount++
      }
    }

    if (modifiedOddsCount > 0) {
      _setChangedLiveOdds(changedLiveOdds)
    }
  }

  const acceptChanges = async () => {
    let changedOdds = []

    const changedOddsFromItemErrors = accumulateBetSlipItemErrors()?.filter(
      (itemError) =>
        itemError.status === CheckBetSlipItemStatus.OddLowered || itemError.status === CheckBetSlipItemStatus.OddRaised,
    )

    changedOddsFromItemErrors.forEach((itemError) => {
      const item = _betSlipServiceResult.items?.find((i) => i.oddId === itemError.oddId)
      const changedOdd = new BetServiceChangedOdd(item.oddId, item.oddValue, itemError.oddValue)
      const existingChangedLiveOdd = _changedLiveOdds.find((odd) => odd.oddId === changedOdd.oddId)
      if (!existingChangedLiveOdd) {
        changedOdds.push(changedOdd)
      }
    })

    await betSlipService.acceptChangedOdds([...changedOdds, ..._changedLiveOdds], _betType)

    _setChangedLiveOdds([])
  }

  const setStake = async (stake: number, stakeMode: StakeMode): Promise<boolean> => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    const checkBetResults = _betsToSubmit?.map((bet) => bet.checkBetResult)
    const betAmount = _betsToSubmit?.length

    if (isLoggedInCustomer(user) && stake * betAmount > _account?.balance) {
      stake = floorNumber(_account.balance / betAmount)
      if (_account.type === AccountType.FreeBet) {
        stakeExceededFreebetAccountBalanceEvent.next(stake)
      } else {
        stakeExceededMainAccountBalanceEvent.next(stake)
      }
    }

    if (checkBetResults?.length > 0) {
      const minimumStake = Math.max(...checkBetResults?.map((cbr) => cbr.minimumStake), 1)
      const maximumStake = Math.min(...checkBetResults?.map((cbr) => cbr.maximumStake))

      if (stake > maximumStake && maximumStake != 0 && maximumStake < _account?.balance) {
        maxStakeExceededEvent.next(floorNumber(maximumStake))
        stake = floorNumber(maximumStake)
      }

      if (stake < minimumStake && minimumStake != 0) {
        minStakeNotReachedEvent.next(floorNumber(minimumStake))
        stake = floorNumber(minimumStake)
      }
    }

    if (stake != _stake) {
      _setStake(stake)

      const request = { stake, stakeMode, refreshOddValues: true, acceptedChangedOdds: [] }
      await betSlipService.setStake(request, _betType)

      return true
    }

    return false
  }

  const toggleAccount = async () => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    let account = null

    if (_account?.type === AccountType.FreeBet) {
      account = mainAccount
    } else if (freebetAccount?.balance > 0 && freebetAccount != null) {
      account = freebetAccount
      _setSelectedTokenBonus(null)
    }

    if (account.id === _account.id) return

    _setAccount(account)

    if (_stake > account.balance) {
      const stake = floorNumber(account.balance)
      _setStake(stake)
      betSlipService.changeStake(stake, 'totalStake')
    }

    await betSlipService.setAccountAndGivenTokenBonusId(
      account,
      account.type === AccountType.Main ? _selectedTokenBonus : null,
      _betType,
    )
  }

  const placeBetSlip = async (): Promise<void> => {
    if (_betslipState != BetslipState.Initial || _isBetSlipCalculationInProgress) return

    _setBetslipState(BetslipState.PlacingBet)

    try {
      const request: BetSlipSubmitRequest = {
        betsToSubmit: _betsToSubmit.map((bet) => bet.identifier),
        maxGainConfirmed: false,
        refreshOddValues: false,
      }

      await betSlipService.submitBet(request)
    } catch (error) {
      _setBetslipState(BetslipState.PlaceBetFailed)
    }
  }

  const shareBetSlip = async (): Promise<void> => {
    if (
      _betslipState != BetslipState.Initial ||
      _isBetSlipCalculationInProgress ||
      !sportsBettingSettings?.betSharingEnabled
    )
      return

    try {
      const request: BetSlipShareRequest = {
        betsToShare: _betsToSubmit.map((bet) => bet.identifier),
      }

      const referenceId = await betSlipService.shareBets(request)
      if (referenceId == null || referenceId == '') throw 'Empty Reference'

      const shareData: ShareBetSlipResult = {
        title:
          getCmsContentDataFieldValue(betSharingTextsContent, 'title')?.replace(
            '{{operatorName}}',
            frontEndSettings?.brandName,
          ) ?? '',
        text:
          getCmsContentDataFieldValue(betSharingTextsContent, 'text')?.replace(
            '{{operatorName}}',
            frontEndSettings?.brandName,
          ) ?? '',
        url: document.location.origin + '/sports?referenceId=' + referenceId,
      }

      _setShareData(shareData)
    } catch (error) {
      console.log(`[DEBUG] shareBetSlipError ${error}`)
    }
  }

  const clearBetSlip = (): void => {
    const account = _accountRef?.current
    const balance = account?.balance
    const defaultStake = _defaultStakeRef?.current
    const stake = floorNumber(balance ? Math.min(defaultStake, balance) : defaultStake) ?? 10

    if (account?.id != _mainAccountRef?.current?.id) {
      _setAccount(_mainAccountRef?.current)
      betSlipService.changeAccount(_mainAccountRef?.current?.id)
    }

    _setBetType(BetTypes.Combi)
    _setBetSlipServiceResult(null)
    _setBetSystems([])
    _setSelectedBetSystem(null)
    _setBetslipState(BetslipState.Initial)
    _setChangedLiveOdds([])
    _setStake(stake)
    _setSelectedTokenBonus(null)
    _setAvailableTokenBonuses([])
    _setShareData(null)

    betSlipService.clearBetSlip()
    betSlipService.changeStake(stake, 'totalStake')
  }

  const shareBetWarning = (): void => {
    _setGetSharedBetWarning(true)
  }

  const getOddState = (odd: Odd, gameId: number): BetSlipContextOddState => {
    const oddIsBeingChecked =
      _betSlipServiceResult?.bets?.find((bet) => bet.betSlip?.items?.find((item) => item.oddId === odd.id) != null) ==
        null && _betSlipServiceResult?.items?.find((item) => item.oddId === odd.id) != null

    if (oddIsBeingChecked) return 'checking'
    else if (_betSlipServiceResult?.items?.find((item) => item.odd.id === odd.id)) return 'selected'
    else if (_betSlipServiceResult?.items?.find((item) => item.gameId === gameId)) return 'other_game_odd_selected'
    else return 'unselected'
  }

  const removeOdd = async (oddId: number): Promise<void> => {
    await betSlipService.removeOdd(oddId, true, _betType)
  }

  const toggleOdd = async (odd: Odd, market: Market, game: Game): Promise<void> => {
    if (
      _betslipStateRef.current !== BetslipState.Initial &&
      _betslipStateRef.current !== BetslipState.PlaceBetSuccessful
    )
      return

    if (_betslipStateRef.current === BetslipState.PlaceBetSuccessful) clearBetSlip()

    _setShareData(null)

    if (_betSlipServiceResult?.items?.find((item) => item.oddId === odd.id)) {
      await betSlipService.removeOdd(odd.id, true, _betTypeRef.current)
    } else {
      await betSlipService.addOdd(game, market, odd, true, _betTypeRef.current)
    }
  }

  const addBetBuilderOdd = async (odd: Odd, market: Market, game: Game): Promise<void> => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    _setShareData(null)

    await betSlipService.addBetBuilderOdd(game, market, odd, true, _betTypeRef.current)
  }

  const toggleOddBanked = async (item: BetSlipServiceBetSlipItem) => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    if (item.type === BetSlipItemType.Banker) {
      await betSlipService.unmarkOddBanked(item.oddId, true, _betType)
    } else {
      await betSlipService.markOddBanked(item.oddId, true, _betType)
    }
  }

  const onChangeBetType = async (betType: BetTypes) => {
    if (_betslipStateRef.current != BetslipState.Initial) return

    if (betType === _betType) return

    _setBetType(betType)
    betSlipService.changeStake(_stake, betType === BetTypes.Combi ? 'totalStake' : 'stakePerBet')

    if (betType === BetTypes.System && commonContext.oddsFormat === OddValueFormat.PotentialWinnings) {
      systemBetsNoPotentialWinningModeEvent.next()
    }

    if (betType === BetTypes.Single && _betSlipServiceResult?.items?.length > 1) {
      _setSelectedTokenBonus(null)
      betSlipService.setGivenTokenBonusId(null)
    }

    // if (_betSlipServiceResult != null && _betSlipServiceResult.items?.length > 0) {
    await betSlipService.createBetsForBetType(betType)
    // }
  }

  const getOddValue = (odd: Odd, game: Game, currency?: Currency): string | number => {
    if (_betTypeRef.current === BetTypes.System && commonContext.oddsFormat === OddValueFormat.PotentialWinnings) {
      return getDecimalOddsFormatValue(odd.value)
    }

    switch (commonContext.oddsFormat) {
      case OddValueFormat.PotentialWinnings:
        return getReturnOddsFormatValue(odd, game, currency)
      case OddValueFormat.DecimalOdds:
        return getDecimalOddsFormatValue(odd.value)
      case OddValueFormat.AmericanOdds:
        return getAmericanOddsFormatValue(odd.value)
      case OddValueFormat.HongKongOdds:
        return getHongKongOddsFormatValue(odd.value)
      case OddValueFormat.Fractional:
        return getFractionalOddsFormatValue(odd.value)
    }
  }

  const getCombiReturnOddsFormatValue = (
    odd: Odd,
    game: CardEventModel,
    currency: Currency,
    isFreebet: boolean,
  ): 'calculating' | 'error ' | string => {
    let bet = _betSlipServiceResultRef.current?.bets?.find((bet) => bet.identifier[0] === 'c')
    const stake = _stake - _betValues.stakeFee - _betValues.stakeTax

    const maxReturnReached = bet && bet?.betSlip?.initialMaximumGain > bet?.betSlip?.initialMaximumReturnedAmount
    const maxReturn = bet?.betSlip?.initialMaximumReturnedAmount
    const currentMaximumReturnedAmount = bet?.betSlip?.currentMaximumReturnedAmount
    const hasCheckBetError = bet?.checkBetResult?.errors?.length > 0
    const hasBlockingItemError =
      bet?.checkBetResult?.itemErrors?.filter((err) => betSlipService.isBlockingItemError(err))?.length > 0
    const oddState = getOddState(odd, game.id)

    if (hasCheckBetError || hasBlockingItemError) {
      if (oddState === 'selected') {
        return 'error'
      }
      const value = odd.value * stake
      return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
    }

    if (bet?.betSlip == null || currentMaximumReturnedAmount <= 0) {
      if (_betSlipServiceResultRef.current?.items?.length > 0) {
        return 'calculating'
      }

      const value = odd.value * stake
      return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
    }

    const oddNotCalculatedYet =
      bet.betSlip?.items?.find((item) => item.oddId === odd.id) == null &&
      _betSlipServiceResultRef.current?.items?.find((item) => item.oddId === odd.id) != null

    if (oddNotCalculatedYet) {
      return 'calculating'
    }

    if (oddState === 'selected') {
      return formatOddMoneyOutcome(bet?.betSlip?.currentMaximumReturnedAmount, 2, currency) as string
    }

    const oddOfSameGame = bet?.betSlip?.items?.find((item) => item.gameId === game.id)
    if (oddOfSameGame) {
      const maxReturnWithoutSameGameOdd =
        (isFreebet ? bet?.betSlip?.currentMaximumGain + stake : bet?.betSlip?.currentMaximumGain) /
        oddOfSameGame.oddValue
      const value = maxReturnReached
        ? Math.min(odd.value * maxReturnWithoutSameGameOdd, maxReturn)
        : odd.value * maxReturnWithoutSameGameOdd
      return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
    }

    const value = maxReturnReached
      ? maxReturn
      : odd.value *
        (currentMaximumReturnedAmount != 0
          ? isFreebet
            ? currentMaximumReturnedAmount + stake
            : currentMaximumReturnedAmount
          : stake)
    return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
  }

  const getSinglesReturnOddsFormatValue = (
    odd: Odd,
    game: CardEventModel,
    currency: Currency,
    isFreebet: boolean,
  ): string => {
    const bets: BetSlipServiceStateBet[] = _betSlipServiceResultRef.current?.bets?.filter(
      (bet) => bet.identifier[0] === 's',
    )

    let currentMaximumReturnedAmount = 0

    for (let bet of bets) {
      if (bet?.betSlip != null) {
        currentMaximumReturnedAmount += bet.betSlip.currentMaximumReturnedAmount ?? 0
      }
    }

    if (bets == null || bets.length === 0) {
      if (_betSlipServiceResultRef.current?.items?.length > 0) {
        return 'calculating'
      }

      const value = odd.value * _stake
      return formatOddMoneyOutcome(isFreebet ? value - _stake : value, 2, currency) as string
    }

    const oddNotCalculatedYet =
      bets.find((bet) => bet.betSlip?.items?.find((item) => item.oddId === odd.id) != null) == null &&
      _betSlipServiceResultRef.current?.items?.find((item) => item.oddId === odd.id) != null

    if (oddNotCalculatedYet) {
      return 'calculating'
    }

    if (getOddState(odd, game.id) === 'selected') {
      const bet = bets.find((bet) => bet?.betSlip?.items?.find((item) => item.oddId === odd.id))
      const hasCheckBetError = bet?.checkBetResult?.errors?.length > 0
      const hasBlockingItemError =
        bet?.checkBetResult?.itemErrors?.filter((err) => betSlipService.isBlockingItemError(err))?.length > 0

      if (hasCheckBetError || hasBlockingItemError) {
        return 'error'
      }
      return formatOddMoneyOutcome(currentMaximumReturnedAmount, 2, currency) as string
    }

    const stake = _stake - (_betValues.stakeTax + _betValues.stakeFee) / Math.max(_betValues.betAmount, 1)
    const gainTaxPercentage = _betValues.gainTax / Math.max(_betValues.maxGain, 1)
    const betWithOddOfSameGame = bets.find((bet) => bet?.betSlip?.items?.find((item) => item.gameId === game.id))

    let value = 0
    const oddGain = odd.value * stake
    const oddGainTax = oddGain * gainTaxPercentage

    if (betWithOddOfSameGame) {
      const maximumReturnedAmountWithoutOddOffSameGame = bets
        .filter((bet) => bet.identifier != betWithOddOfSameGame.identifier)
        .reduce((prev, next) => (prev += next.betSlip?.currentMaximumReturnedAmount), 0)

      value = maximumReturnedAmountWithoutOddOffSameGame + oddGain - oddGainTax
    } else {
      value = currentMaximumReturnedAmount + oddGain - oddGainTax
    }

    return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
  }

  const getReturnOddsFormatValue = (odd: Odd, game: CardEventModel, currency?: Currency): string => {
    const betSlipServiceResult = _betSlipServiceResultRef.current
    const stake = _stakeRef.current
    const isFreebet = _accountRef.current?.type === AccountType.FreeBet

    if (betSlipServiceResult?.bets == null || betSlipServiceResult?.bets?.length === 0) {
      const value = odd.value * stake
      return formatOddMoneyOutcome(isFreebet ? value - stake : value, 2, currency) as string
    }

    switch (_betType) {
      case BetTypes.Single:
        return getSinglesReturnOddsFormatValue(odd, game, currency, isFreebet)
      case BetTypes.Combi:
        return getCombiReturnOddsFormatValue(odd, game, currency, isFreebet)
    }
  }

  const getGames = (): CardEventModel[] => {
    return _betSlipServiceResult?.items?.map((item) => item.game)
  }

  const checkForItemErrors = (oddId: number): BetSlipContextItemError[] => {
    const itemErrors: CheckBetSlipItemError[] = accumulateBetSlipItemErrors()

    const errors = itemErrors?.filter((err) => err.oddId === oddId)

    return errors?.map((error) => {
      return { ...error, type: betSlipService.isBlockingItemError(error) ? 'error' : 'warning' }
    })
  }

  const isBlockingItemError = (error: CheckBetSlipItemError) => {
    return betSlipService.isBlockingItemError(error)
  }

  const accumulateBetSlipItemErrors = (): BetSlipContextItemError[] => {
    const itemErrors: BetSlipContextItemError[] = []

    _betsToSubmit?.forEach((bet) => {
      bet.checkBetResult?.itemErrors?.forEach((itemError) => {
        if (itemErrors.find((e) => e.oddId === itemError.oddId) == null) {
          itemErrors.push({ ...itemError, type: betSlipService.isBlockingItemError(itemError) ? 'error' : 'warning' })
        }
      })
    })

    return itemErrors
  }

  const accumulateCheckBetSlipErrors = () => {
    const errors: CheckBetSlipError[] = []

    _betsToSubmit?.forEach((bet) => {
      bet.checkBetResult?.errors?.forEach((error) => {
        if (errors.find((e) => e.type === error.type) == null) errors.push(error)
      })
    })

    return errors
  }

  const accumulatePlaceBetSlipErrors = () => {
    const errors: PlaceBetSlipResultType[] = []

    _betSlipServiceResult?.bets?.forEach((bet) => {
      const placeResultType = bet?.placeBetResult?.placeResultType
      if (placeResultType && !errors.includes(placeResultType)) errors.push(placeResultType)
    })

    return errors
  }

  const placeBetPossible = (ignoreBetCalculation: boolean = false): boolean => {
    const itemErrors = accumulateBetSlipItemErrors()
    const errors = accumulateCheckBetSlipErrors()

    return (
      !_stakeSelectorActive &&
      _betsToSubmit?.length > 0 &&
      errors?.length === 0 &&
      itemErrors?.filter((err) => betSlipService.isBlockingItemError(err))?.length === 0 &&
      (!ignoreBetCalculation ? _isBetSlipCalculationInProgress === false : true)
    )
  }

  const mustAcceptOddsChanges = (): boolean => {
    return (
      betSettings?.oddValueChangeHandling !== UserChangeHandling.AcceptAllChanges &&
      (_changedLiveOdds?.filter((liveOdd) => {
        const oddChange = liveOdd.newValue - liveOdd.oldValue
        if (oddChange < 0) return liveOdd
        if (oddChange > 0 && betSettings?.oddValueChangeHandling === UserChangeHandling.AlwaysAsk) return liveOdd
      })?.length > 0 ||
        accumulateBetSlipItemErrors()?.find(
          (itemError) =>
            itemError.status === CheckBetSlipItemStatus.OddLowered ||
            (itemError.status === CheckBetSlipItemStatus.OddRaised &&
              betSettings?.oddValueChangeHandling === UserChangeHandling.AlwaysAsk),
        ) != null)
    )
  }

  const mustAcceptAvailabilityChanges = (): boolean => {
    const itemErrors = accumulateBetSlipItemErrors()?.filter((err) => betSlipService.isBlockingItemError(err))

    return itemErrors?.length > 0
  }

  const mustAcceptChanges = (): { mustAcceptOddsChanges: boolean; mustAcceptAvailabilityChanges: boolean } => {
    return {
      mustAcceptOddsChanges: mustAcceptOddsChanges(),
      mustAcceptAvailabilityChanges: mustAcceptAvailabilityChanges(),
    }
  }

  const validTokenBonuses = (): ValidTokenBonus[] => {
    if (_betsToSubmit?.length === 1) {
      return _availableTokenBonuses
    }
    return []
  }

  const toggleTokenBonus = async (givenBonusTokenId: number): Promise<void> => {
    if (_selectedTokenBonus != givenBonusTokenId) {
      const tokenBonus = _availableTokenBonuses?.find((b) => b.givenBonusId === givenBonusTokenId)

      if (tokenBonus) {
        if (tokenBonus.bonusType === BonusType.FreeBetToken) {
          _setStake(tokenBonus.freeBetTokenAmount)
          _setAccount(freebetTokenAccount)
          _setSelectedTokenBonus(givenBonusTokenId)
          betSlipService.changeStake(tokenBonus.freeBetTokenAmount, 'totalStake')
          return await betSlipService.setAccountAndGivenTokenBonusId(freebetTokenAccount, givenBonusTokenId, _betType)
        } else if (_account?.type !== AccountType.Main) {
          _setAccount(mainAccount)
          _setSelectedTokenBonus(givenBonusTokenId)
          return await betSlipService.setAccountAndGivenTokenBonusId(mainAccount, givenBonusTokenId, _betType)
        }

        _setSelectedTokenBonus(givenBonusTokenId)
        betSlipService.setGivenTokenBonusId(givenBonusTokenId)
        return await betSlipService.createBetsForBetType(_betType)
      }
    }

    _setAccount(mainAccount)
    _setSelectedTokenBonus(null)
    await betSlipService.setAccountAndGivenTokenBonusId(mainAccount, null, _betType)
  }

  const value: BetslipContextProps = {
    initialized: _initialized,
    betSlipServiceResult: _betSlipServiceResult,
    betslipState: _betslipState,
    account: _account,
    stake: _stake,
    totalStake: _betValues.totalStake,
    stakePerBet: _betValues.stakePerBet,
    betAmount: _betValues.betAmount,
    maxOdd: _betValues.maxOdd,
    initialMaxOdd: _betValues.initialMaxOdd,
    stakeBonus: _betValues.stakeBonus,
    gainBonus: _betValues.gainBonus,
    stakeFee: _betValues.stakeFee,
    stakeTax: _betValues.stakeTax,
    chargedAmount: _betValues.chargedAmount,
    gainTax: _betValues.gainTax,
    maxReturn: _betValues.maxReturn,
    betslips: _betsToSubmit?.map((bet) => bet.betSlip),
    betSystems: _betSystems,
    selectedBetSystem: _selectedBetSystem,
    toggleBetSystem,
    selectedOdds: [...(_betSlipServiceResult?.items ?? [])],
    betSlipItemErrors: accumulateBetSlipItemErrors(),
    checkBetSlipErrors: accumulateCheckBetSlipErrors(),
    placeBetSlipErrors: accumulatePlaceBetSlipErrors(),
    placeBetLiveDelay: _placeBetLiveDelay,
    betType: _betType,
    setStake,
    toggleAccount,
    toggleOdd,
    toggleOddBanked,
    changedLiveOdds: _changedLiveOdds,
    mustAcceptChanges,
    acceptChanges: acceptChanges,
    placeBetSlip,
    shareBetSlip,
    restoreBetSlip,
    clearBetSlip,
    getOddState,
    getOddValue,
    getGames,
    removeOdd,
    checkForItemErrors,
    placeBetPossible,
    maxBetSlipItemsReachedEvent,
    maxReturnReachedEvent,
    nonCombinableGameAddedEvent,
    checkBetSlipErrorEvent,
    maxStakeExceededEvent,
    minStakeNotReachedEvent,
    stakeExceededFreebetAccountBalanceEvent,
    stakeExceededMainAccountBalanceEvent,
    systemBetsNoPotentialWinningModeEvent,
    shareBetWarning,
    retrieveSharedBetErrorEvent,
    setBetType: onChangeBetType,
    isBetTypeAcitvated,
    isBlockingItemError,
    validTokenBonuses,
    selectedTokenBonus: _availableTokenBonuses?.find((x) => x.givenBonusId === _selectedTokenBonus),
    toggleTokenBonus,
    addBetBuilderOdd,
    shareData: _shareData,
    clearShareData: () => _setShareData(null),
    isShared: _isShared,
    stakeSelectorActive: _stakeSelectorActive,
    setStakeSelectorActive: _setStakeSelectorActive,
  }

  return <BetslipContext.Provider value={value}>{children}</BetslipContext.Provider>
}

export default BetslipProvider
