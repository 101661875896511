import { OddValueFormat } from '../hooks/useOddsFormat'
import { getIntlAmericanOddNumberFormat, getIntlDecimalOddNumberFormat } from './internationalization'
import { greatestCommonDivider } from './number'
import { Market } from '@arland-bmnext/api-data'

export const TotalsMarketTypeIds = [17, 18, 19, 34, 35, 62, 63, 64, 73, 82, 83, 84]
export const HandicapMarketTypeIds = [16, 61, 81, 107, 110]
export const FreetextMarketTypeIds = [492, 494, 517]
export const GoalscorerMarketTypeIds = [38, 36, 37]

export const getFormattedOddValue = (
  oddsFormat: OddValueFormat,
  oddValue: number,
  maximumFractionDigits: number = 3,
): string => {
  switch (oddsFormat) {
    case OddValueFormat.AmericanOdds:
      return getIntlAmericanOddNumberFormat(getAmericanOddsFormatValue(oddValue))
    case OddValueFormat.HongKongOdds:
      return getIntlDecimalOddNumberFormat(getHongKongOddsFormatValue(oddValue))
    case OddValueFormat.Fractional:
      return getFractionalOddsFormatValue(oddValue)
    default:
      return getIntlDecimalOddNumberFormat(getDecimalOddsFormatValue(oddValue), maximumFractionDigits)
  }
}

export const getDecimalOddsFormatValue = (oddValue: number) => {
  return oddValue
}

export const getAmericanOddsFormatValue = (oddValue: number) => {
  if (oddValue >= 2) {
    return (oddValue - 1) * 100
  } else {
    return -100 / (oddValue - 1)
  }
}

export const getHongKongOddsFormatValue = (oddValue: number) => {
  return oddValue - 1
}

export const getFractionalOddsFormatValue = (oddValue: number) => {
  const len = oddValue.toString().length - 2
  let denominator = Math.pow(10, len)
  let numerator = oddValue * denominator
  const divisor = greatestCommonDivider(numerator, denominator)

  numerator /= divisor
  denominator /= divisor

  return Math.floor(numerator) + '/' + Math.floor(denominator)
}

export const getMostBalancedLineForMarkets = (markets: Market[]): Market => {
  if (markets == null || markets.length === 0) return null

  const filteredByOutcomes = markets.filter((m) => m.odds?.length >= 2)
  if (filteredByOutcomes.length === 0) return null

  const sortedMarkets = filteredByOutcomes.sort(
    (a, b) => Math.abs(a.odds[0].value - a.odds[1].value) - Math.abs(b.odds[0].value - b.odds[1].value),
  )
  return sortedMarkets[0]
}
